<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <card-initializing
        v-if="isInitializing"
        :text="`Loading Client ${client_id}`"
      />
      <card-not-found
        v-else-if="!clientExists"
        :back-button-route="CLIENTS_ROUTE"
        :text="`Client Not Found (${client_id})`"
        back-button-tooltip="Client List"
      />
      <v-card
        v-else
        class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
        max-width="100%"
        outlined
      >
        <CardToolbar
          :back-button-route="CLIENTS_ROUTE"
          :icon="CLIENTS_ICON"
          :title="`${CLIENT_VUE} ${client_id}`"
          @collapse-all="panel = []"
          @expand-all="panel = panelsExpanded"
          @refresh-data="refreshClient()"
          back-button-tooltip="Client List"
          button-mode
          include-back
          include-collapse
          include-expand
          include-help
          include-refresh
        />
        <HelpText :page="CLIENT_VUE" />

        <v-expansion-panels
          v-model="panel"
          class="mt-4 pb-4 px-0"
          multiple
          popout
        >
          <crud-expansion-panel
            :disable-save="isClientSaveDisabled"
            :help-page="CLIENT_VUE_CLIENT_EXPAND"
            :loading="loading[CLIENT]"
            :readonly="!isClientEditing"
            :saving="saving[CLIENT]"
            @cancel-edit="client.cancelEdit(CLIENT)"
            @enable-edit="client.enableEdit(CLIENT)"
            @save-data="updateClientData(CLIENT, client[DATA][CLIENT])"
            type="Client"
          >
            <template v-slot:title>
              <ExpansionPanelTitleWithText
                :display="isClientPanelTextDisplayed"
                title="Client"
                :text="client.getSummaryText(CLIENT)"
              />
            </template>
            <template v-slot:content>
              <v-fade-transition>
                <v-form
                  v-if="!loading[CLIENT]"
                  v-model="validated[CLIENT]"
                  @submit.prevent
                >
                  <v-row v-for="(fieldGroup, i) in client.getFieldGroups(CLIENT)" :key="i">
                    <v-col
                      v-for="(field, j) in fieldGroup" :key="j"
                      dense
                      cols="12" :sm="field.smColumns" :md="field.mdColumns"
                    >
                      <v-text-field
                        v-if="field.fieldType === TEXT"
                        v-model="client[DATA][CLIENT][field.property]"
                        :clearable="isClientEditing"
                        :counter="field.counter"
                        :label="field.label"
                        :readonly="!isClientEditing"
                        :rules="field.rules"
                        dense
                      />
                      <v-select
                        v-else-if="field.fieldType === SELECT"
                        v-model="client[DATA][CLIENT][field.property]"
                        :item-text="(field.textProperty !== null) ? field.textProperty : null"
                        :item-value="(field.textProperty !== null) ? field.textProperty : null"
                        :items="field.items"
                        :label="field.label"
                        :readonly="!isClientEditing"
                        dense
                      />
                      <text-field-date-picker
                        v-else-if="field.fieldType === DATE"
                        v-model="client[DATA][CLIENT][field.property]"
                        dense
                        :readonly="!isClientEditing"
                        :label="field.label"
                        :clearable="isClientEditing"
                        :rules="field.rules"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-fade-transition>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            v-if="!client.isCoApplicant()"
            @cancel-edit="client.cancelEdit(CLIENT_ADDRESS)"
            @enable-edit="client.enableEdit(CLIENT_ADDRESS)"
            @save-data="updateClientData(CLIENT_ADDRESS, client[DATA][CLIENT_ADDRESS])"
            :disable-save="isAddressSaveDisabled"
            :help-page="CLIENT_VUE_ADDRESS_EXPAND"
            :loading="loading[CLIENT_ADDRESS]"
            :readonly="!isAddressEditing"
            :saving="saving[CLIENT_ADDRESS]"
            type="Client Address"
          >
            <template v-slot:title>
              <ExpansionPanelTitleWithText
                :display="isAddressPanelTextDisplayed"
                title="Address"
                :text="client.getSummaryText(CLIENT_ADDRESS)"
              />
            </template>
            <template v-slot:content>
              <v-fade-transition>
                <v-form
                  v-if="!loading[CLIENT_ADDRESS]"
                  v-model="validated[CLIENT_ADDRESS]"
                  @submit.prevent
                >
                  <v-row v-for="(fieldGroup, i) in client.getFieldGroups(CLIENT_ADDRESS)" :key="i">
                    <v-col
                      v-for="(field, j) in fieldGroup" :key="j"
                      dense
                      cols="12" :sm="field.smColumns" :md="field.mdColumns"
                    >
                      <v-text-field
                        v-if="field.fieldType === TEXT"
                        v-model="client[DATA][CLIENT_ADDRESS][field.property]"
                        :clearable="isAddressEditing"
                        :counter="field.counter"
                        dense
                        :readonly="!isAddressEditing"
                        :rules="field.rules"
                        :label="field.label"
                      />
                      <v-select
                        v-else-if="field.fieldType === SELECT"
                        v-model="client[DATA][CLIENT_ADDRESS][field.property]"
                        dense
                        :items="field.items"
                        :item-text="(field.textProperty !== null) ? field.textProperty : null"
                        :item-value="(field.textProperty !== null) ? field.textProperty : null"
                        :readonly="!isAddressEditing"
                        :label="field.label"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-fade-transition>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            @cancel-edit="client.cancelEdit(CLIENT_CONTACT)"
            @enable-edit="client.enableEdit(CLIENT_CONTACT)"
            @save-data="updateClientData(CLIENT_CONTACT, client[DATA][CLIENT_CONTACT])"
            :disable-save="isContactSaveDisabled"
            :help-page="CLIENT_VUE_CONTACT_EXPAND"
            :loading="loading[CLIENT_CONTACT]"
            :readonly="!isContactEditing"
            :saving="saving[CLIENT_CONTACT]"
            type="Client Contact"
          >
            <template v-slot:title>
              <ExpansionPanelTitleWithText
                :display="isContactPanelTextDisplayed"
                title="Contact"
                :text="client.getSummaryText(CLIENT_CONTACT)"
              />
            </template>
            <template v-slot:content>
              <v-fade-transition>
                <v-form
                  v-if="!loading[CLIENT_CONTACT]"
                  v-model="validated[CLIENT_CONTACT]"
                  @submit.prevent
                >
                  <v-row v-for="(fieldGroup, i) in client.getFieldGroups(CLIENT_CONTACT)" :key="i">
                    <v-col
                      v-for="(field, j) in fieldGroup" :key="j"
                      dense
                      cols="12" :sm="field.smColumns" :md="field.mdColumns"
                    >
                      <v-text-field
                        v-if="field.fieldType === TEXT"
                        v-model="client[DATA][CLIENT_CONTACT][field.property]"
                        :clearable="isContactEditing"
                        :counter="field.counter"
                        dense
                        :readonly="!isContactEditing"
                        :rules="field.rules"
                        :label="field.label"
                      />
                      <v-select
                        v-else-if="field.fieldType === SELECT"
                        v-model="client[DATA][CLIENT_CONTACT][field.property]"
                        dense
                        :items="field.items"
                        :item-text="(field.textProperty !== null) ? field.textProperty : null"
                        :item-value="(field.textProperty !== null) ? field.textProperty : null"
                        :readonly="!isContactEditing"
                        :label="field.label"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-fade-transition>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            v-if="!client.isCoApplicant()"
            @cancel-edit="client.cancelEdit(CLIENT_PROGRAM_CONTROL)"
            @enable-edit="client.enableEdit(CLIENT_PROGRAM_CONTROL)"
            @save-data="updateClientData(CLIENT_PROGRAM_CONTROL, client[DATA][CLIENT_PROGRAM_CONTROL])"
            :disable-save="isProgramControlSaveDisabled"
            :help-page="CLIENT_VUE_PROGRAM_CONTROL_EXPAND"
            :loading="loading[CLIENT_PROGRAM_CONTROL]"
            :readonly="!isProgramControlEditing"
            :saving="saving[CLIENT_PROGRAM_CONTROL]"
            type="Client Program Controls"
          >
            <template v-slot:title>
              <ExpansionPanelTitleWithText
                :display="isProgramControlPanelTextDisplayed"
                title="Program Controls"
                :text="client.getSummaryText(CLIENT_PROGRAM_CONTROL)"
              />
            </template>
            <template v-slot:content>
              <v-fade-transition>
                <v-form
                  v-if="!loading[CLIENT_PROGRAM_CONTROL]"
                  v-model="validated[CLIENT_PROGRAM_CONTROL]"
                  @submit.prevent
                >
                  <v-row v-for="(fieldGroup, i) in client.getFieldGroups(CLIENT_PROGRAM_CONTROL)" :key="i">
                    <v-col
                      v-for="(field, j) in fieldGroup" :key="j"
                      dense
                      cols="12" :sm="field.smColumns" :md="field.mdColumns"
                    >
                      <v-text-field
                        v-if="field.fieldType === TEXT"
                        v-model="client[DATA][CLIENT_PROGRAM_CONTROL][field.property]"
                        :clearable="isProgramControlEditing"
                        :counter="field.counter"
                        dense
                        :readonly="!isProgramControlEditing"
                        :rules="field.rules"
                        :label="field.label"
                      />
                      <v-select
                        v-else-if="field.fieldType === SELECT"
                        v-model="client[DATA][CLIENT_PROGRAM_CONTROL][field.property]"
                        dense
                        :items="field.items"
                        :item-text="(field.textProperty !== null) ? field.textProperty : null"
                        :item-value="(field.textProperty !== null) ? field.textProperty : null"
                        :readonly="!isProgramControlEditing"
                        :label="field.label"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-fade-transition>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            disable-toolbar
            :loading="loading[CLIENT_AGENT_ASSN]"
            type="Client-Agent Association"
          >
            <template v-slot:title>
              <ExpansionPanelTitleWithText
                :display="isAgentAssociationPanelTextDisplayed"
                title="Associated Agents"
                :text="client.getSummaryText(CLIENT_AGENT_ASSN)"
              />
            </template>
            <template v-slot:content>
              <card-group-associations
                :association-data="client[DATA][CLIENT_AGENT_ASSN]"
                :association-target-data="filteredAgentAssociations"
                :create-function="createClientData"
                :delete-function="deleteClientData"
                :deleting="deleting[CLIENT_AGENT_ASSN]"
                disable-action
                :saving="saving[CLIENT_AGENT_ASSN]"
                @search-input="agentAssociationSearch = $event"
                :type="CLIENT_AGENT_ASSN"
              >
              </card-group-associations>
            </template>
          </crud-expansion-panel>

          <crud-expansion-panel
            disable-toolbar
            :loading="loading[CLIENT_MORTGAGE_DEAL_ASSN]"
            type="Client-Mortgage Deal Association"
          >
            <template v-slot:title>
              <ExpansionPanelTitleWithText
                :display="isMortgageDealAssociationPanelTextDisplayed"
                title="Associated Mortgage Deals"
                :text="client.getSummaryText(CLIENT_MORTGAGE_DEAL_ASSN)"
              />
            </template>
            <template v-slot:content>
              <card-group-associations
                :association-data="client[DATA][CLIENT_MORTGAGE_DEAL_ASSN]"
                :association-target-data="filteredMortgageDealAssociations"
                :create-function="createClientData"
                :delete-function="deleteClientData"
                :deleting="deleting[CLIENT_MORTGAGE_DEAL_ASSN]"
                disable-action
                :saving="saving[CLIENT_MORTGAGE_DEAL_ASSN]"
                @search-input="mortgageDealAssociationSearch = $event"
                :type="CLIENT_MORTGAGE_DEAL_ASSN"
              >
              </card-group-associations>
            </template>

          </crud-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { differenceWith, isEqual, isNil, orderBy, range } from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { ACTIONS, EXPANDS, ICONS, ROUTES, VUES, MESSAGE_TYPE, VALID_TABLES, DATA } from '../constants'
import { TYPE as MORTGAGE_DEAL_TYPE } from '../models/dto/MortgageDeal'
import { TYPE as AGENT_TYPE } from '../models/dto/Agent'
import { isTypeIterable, TYPE as CLIENT_TYPE } from '../models/dto/Client'
import { FIELD_TYPES } from '../models/field_configs/FieldConfig'
import { now } from '../services/utility.service'

export default {
  components: {
    CardGroupAssociations: () => import('../components/layout/CardGroupAssociations.vue'),
    CardInitializing: () => import('../components/layout/cards/CardInitializing.vue'),
    CardNotFound: () => import('../components/layout/cards/CardNotFound.vue'),
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    CrudExpansionPanel: () => import('../components/layout/CrudExpansionPanel.vue'),
    ExpansionPanelTitleWithText: () => import('../components/layout/ExpansionPanelTitleWithText.vue'),
    HelpText: () => import('../components/layout/HelpText.vue'),
    TextFieldDatePicker: () => import('../components/input/TextFieldDatePicker.vue'),
  },
  data: () => ({
    client_id: null,      // Client ID of this client.
    client: {},           // The client.
    clientExists: true,   // Flag that indicates if this client exists.
    isCoApplicant: false, // Flag that indicates if this client is a co-applicant.

    isInitializing: false, // Has this component page been initialized?

    agentAssociationSearch: '',        // Search field for the client-agent associations.
    mortgageDealAssociationSearch: '', // Search field for the mortgage-deal-agent associations.

    panelCount: 0,      // Number of expansion panels.
    panel: [],          // Expansion panel state (active panels).
    panelsExpanded: [], // Array for "all panels active".

    // Lists of data used by client data-types.
    lists: {
      [VALID_TABLES.PROVINCE]: [],
      [AGENT_TYPE.AGENT]: [],
      [MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL]: [],
    },

    // Stores whether a data-type is currently "deleting" data.
    deleting: {
      [CLIENT_TYPE.CLIENT]: false,
      [CLIENT_TYPE.CLIENT_ADDRESS]: false,
      [CLIENT_TYPE.CLIENT_CONTACT]: false,
      [CLIENT_TYPE.CLIENT_AGENT_ASSN]: false,
      [CLIENT_TYPE.CLIENT_PROGRAM_CONTROL]: false,
      [CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN]: false,
    },

    // Stores whether a data-type is currently "loading" data.
    loading: {
      [CLIENT_TYPE.CLIENT]: false,
      [CLIENT_TYPE.CLIENT_ADDRESS]: false,
      [CLIENT_TYPE.CLIENT_CONTACT]: false,
      [CLIENT_TYPE.CLIENT_AGENT_ASSN]: false,
      [CLIENT_TYPE.CLIENT_PROGRAM_CONTROL]: false,
      [CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN]: false,
    },

    // Stores whether a data-type is currently "saving" data.
    saving: {
      [CLIENT_TYPE.CLIENT]: false,
      [CLIENT_TYPE.CLIENT_ADDRESS]: false,
      [CLIENT_TYPE.CLIENT_CONTACT]: false,
      [CLIENT_TYPE.CLIENT_AGENT_ASSN]: false,
      [CLIENT_TYPE.CLIENT_PROGRAM_CONTROL]: false,
      [CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN]: false,
    },

    // Stores whether the fields for a client data-type are currently valid.
    validated: {
      [CLIENT_TYPE.CLIENT]: false,
      [CLIENT_TYPE.CLIENT_ADDRESS]: false,
      [CLIENT_TYPE.CLIENT_CONTACT]: false,
      [CLIENT_TYPE.CLIENT_AGENT_ASSN]: false,
      [CLIENT_TYPE.CLIENT_PROGRAM_CONTROL]: false,
      [CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN]: false,
    },
  }),
  computed: {
    ...mapGetters({
      getBasicAgentProfiles: 'agent/getBasicAgentProfiles',
      getCachedBasicMortgageDealList: 'mortgageDeal/getCachedBasicList',
      getValidData: 'valid/getData',
      user: 'user/getUser',
    }),

    filteredAgentAssociations () {
      const filteredBySearch = orderBy(this.lists[AGENT_TYPE.AGENT].filter(item => {
        return item.agent_id.toString().includes(this.agentAssociationSearch.toLowerCase()) ||
          item.agent_name.toLowerCase().includes(this.agentAssociationSearch.toLowerCase())
      }), 'agent_id')

      const filteredByCurrent = differenceWith(filteredBySearch, this.client.getData(CLIENT_TYPE.CLIENT_AGENT_ASSN), (a, b) => {
        return isEqual(a.agent_id, b.agent_id) && isEqual(a.profile_id, b.profile_id)
      })

      return filteredByCurrent
    },

    filteredMortgageDealAssociations () {
      const filteredBySearch = orderBy(this.lists[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL].filter(item => {
        return item.mortgage_deal_id.toString().includes(this.mortgageDealAssociationSearch.toLowerCase()) ||
          item.loan_code.toLowerCase().includes(this.mortgageDealAssociationSearch.toLowerCase())
      }), 'mortgage_deal_id')

      const filteredByCurrent = differenceWith(filteredBySearch, this.client.getData(CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN), (a, b) => {
        return isEqual(a.mortgage_deal_id, b.mortgage_deal_id)
      })

      return filteredByCurrent
    },

    isAddressEditing () {
      return this.client.isEditing(CLIENT_TYPE.CLIENT_ADDRESS)
    },
    isClientEditing () {
      return this.client.isEditing(CLIENT_TYPE.CLIENT)
    },
    isContactEditing () {
      return this.client.isEditing(CLIENT_TYPE.CLIENT_CONTACT)
    },
    isProgramControlEditing () {
      return this.client.isEditing(CLIENT_TYPE.CLIENT_PROGRAM_CONTROL)
    },

    isAddressSaveDisabled () {
      this.client[ACTIONS.EDIT][CLIENT_TYPE.CLIENT_ADDRESS] // This is here to force a recalculation on edit.

      return this.saving[CLIENT_TYPE.CLIENT_ADDRESS] || !this.validated[CLIENT_TYPE.CLIENT_ADDRESS] ||
        !this.client.hasDataChanged(CLIENT_TYPE.CLIENT_ADDRESS)
    },
    isClientSaveDisabled () {
      this.client[ACTIONS.EDIT][CLIENT_TYPE.CLIENT] // This is here to force a recalculation on edit.

      return this.saving[CLIENT_TYPE.CLIENT] || !this.validated[CLIENT_TYPE.CLIENT] ||
        !this.client.hasDataChanged(CLIENT_TYPE.CLIENT)
    },
    isContactSaveDisabled () {
      this.client[ACTIONS.EDIT][CLIENT_TYPE.CLIENT_CONTACT] // This is here to force a recalculation on edit.

      return this.saving[CLIENT_TYPE.CLIENT_CONTACT] || !this.validated[CLIENT_TYPE.CLIENT_CONTACT] ||
        !this.client.hasDataChanged(CLIENT_TYPE.CLIENT_CONTACT)
    },
    isProgramControlSaveDisabled () {
      this.client[ACTIONS.EDIT][CLIENT_TYPE.CLIENT_PROGRAM_CONTROL] // This is here to force a recalculation on edit.

      return this.saving[CLIENT_TYPE.CLIENT_PROGRAM_CONTROL] || !this.validated[CLIENT_TYPE.CLIENT_PROGRAM_CONTROL] ||
        !this.client.hasDataChanged(CLIENT_TYPE.CLIENT_PROGRAM_CONTROL)
    },

    isClientPanelTextDisplayed () {
      return !this.panel.includes(0)
    },
    isAddressPanelTextDisplayed () {
      // Hidden if co-applicant.
      return !this.panel.includes(1)
    },
    isContactPanelTextDisplayed () {
      const index = this.client.isCoApplicant() ? 1 : 2
      return !this.panel.includes(index)
    },
    isProgramControlPanelTextDisplayed () {
      // Hidden if co-applicant.
      return !this.panel.includes(3)
    },
    isAgentAssociationPanelTextDisplayed () {
      const index = this.client.isCoApplicant() ? 2 : 4
      return !this.panel.includes(index)
    },
    isMortgageDealAssociationPanelTextDisplayed () {
      const index = this.client.isCoApplicant() ? 3 : 5
      return !this.panel.includes(index)
    },    

    /**
     * Metadata required by API calls.
     */
    apiCallMetadata () {
      return { operator_id: this.user.id, dlc: now(), client_id: this.client_id }
    },
  },
  methods: {
    ...mapActions({
      fetchBasicAgentProfiles: 'agent/fetchBasicAgentProfiles',
      fetchBasicMortgageDealList: 'mortgageDeal/fetchBasicList',
      fetchClient: 'client/fetchClient',
      fetchList: 'client/fetchList',

      createData: 'client/createData',
      deleteData: 'client/deleteData',
      updateData: 'client/updateData',

      enqueueMessage: 'message/enqueueMessage',
      errorMessage: 'message/errorMessage',
      initValidStore: 'valid/initStore',
    }),
    ...mapMutations({
      cacheClient: 'client/cacheClient',
    }),
    /**
     * Send an API request to create data from the data-type and (optional) iterable item.
     * @param {string} type Client.TYPE
     * @param {*} item Item from an iterable Client.TYPE
     */
    createClientData(type, item = null) {
      this.saving[type] = true

      const payload = {
        ...(item) ? item : this.client.getData(type),
        ...this.apiCallMetadata,
      }

      this.createData({ type, payload })
        .then(data => {
          if (isTypeIterable(type)) {
            this.client.appendData(data, type)
          }
          else {
            this.client.setData(data, type)
          }
          this.client.disableEdit(type)
          this.cacheClient({ client: this.client, client_id: payload.client_id })

          this.enqueueMessage({ action: ACTIONS.CREATE, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })
        })
        .catch(error => {
          this.enqueueMessage({ action: ACTIONS.CREATE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
        })
        .finally(() => {
          this.saving[type] = false
        })
    },
    /**
     * Send an API request to delete data from the data-type and (optional) iterable item.
     * @param {string} type Client.TYPE
     * @param {*} item Item from an iterable Client.TYPE
     */
    deleteClientData (type, item) {
      this.deleting[type] = true

      const payload = {
        ...(item) ? item : this.client.getData(type),
      }

      let config = {
        type,
        client_id: payload.client_id,
        id: payload.id
      }

      this.deleteData(config)
        .then(() => {
          if (isTypeIterable(type)) {
            this.client.deleteData(item, type)
          }
          else {
            this.client.setToDefaultData(type)
          }

          this.client.disableEdit(type)
          this.cacheClient({ client: this.client, client_id: payload.client_id })

          this.enqueueMessage({ action: ACTIONS.DELETE, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })
        })
        .catch(error => {
          this.enqueueMessage({ action: ACTIONS.DELETE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
        })
        .finally(() => {
          this.deleting[type] = false
        })
    },
    /**
     * Initialize the constants used in the HTML of this view.
     */
    initTemplateConstants () {
      this.CLIENT = CLIENT_TYPE.CLIENT
      this.CLIENT_ADDRESS = CLIENT_TYPE.CLIENT_ADDRESS
      this.CLIENT_AGENT_ASSN = CLIENT_TYPE.CLIENT_AGENT_ASSN
      this.CLIENT_CONTACT = CLIENT_TYPE.CLIENT_CONTACT
      this.CLIENT_PROGRAM_CONTROL = CLIENT_TYPE.CLIENT_PROGRAM_CONTROL
      this.CLIENT_MORTGAGE_DEAL_ASSN = CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN

      this.DATE = FIELD_TYPES.DATE
      this.SELECT = FIELD_TYPES.SELECT
      this.TEXT = FIELD_TYPES.TEXT

      this.CLIENTS_ROUTE = ROUTES.CLIENTS

      this.CLIENT_VUE = VUES.CLIENT

      this.CLIENT_VUE_ADDRESS_EXPAND = EXPANDS.CLIENT_VUE_ADDRESS
      this.CLIENT_VUE_AGENT_ASSN_EXPAND = EXPANDS.CLIENT_VUE_AGENT_ASSN
      this.CLIENT_VUE_CLIENT_EXPAND = EXPANDS.CLIENT_VUE_CLIENT
      this.CLIENT_VUE_CONTACT_EXPAND = EXPANDS.CLIENT_VUE_CONTACT
      this.CLIENT_VUE_PROGRAM_CONTROL_EXPAND = EXPANDS.CLIENT_VUE_PROGRAM_CONTROL
      this.CLIENT_VUE_MORTGAGE_DEAL_ASSN_EXPAND = EXPANDS.CLIENT_VUE_MORTGAGE_DEAL_ASSN

      this.CLIENTS_ICON = ICONS.CLIENTS
      this.DOWNLOAD_ICON = ICONS.DOWNLOAD
      this.NOT_FOUND_ICON = ICONS.NOT_FOUND

      this.DATA = DATA
    },
    /**
     * Initialize the client DTO for this page.
     */
    async initClient() {
      try {
        const results = await Promise.all([
          this.initValidStore(),
          this.fetchClient({ client_id: this.client_id })
        ])

        // Setup the client and add any valid table dependencies to its metadata.
        this.client = results[1]
        this.clientExists = !isNil(this.client)
      }
      catch (error) {
        this.clientExists = false
        this.errorMessage(`Failed to initialize client.`)
      }

      this.fetchBasicAgentProfiles()
        .then(() => {
          this.lists[AGENT_TYPE.AGENT] = this.getBasicAgentProfiles
        })
        .catch(error => {
          this.errorMessage(`Error fetching basic agent profiles.`)
        })
      
      this.fetchBasicMortgageDealList({ force: false })
        .then(list => {
          this.lists[MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL] = list
        })
        .catch(error => {
          this.errorMessage(`Error fetching basic mortgage deals.`)
        })
    },
    async refreshClient () {
      this.isInitializing = true
      try {
        this.client = await this.fetchClient({ client_id: this.client_id, force: true })
        this.clientExists = !isNil(this.client)
      }
      catch (error) {
        this.clientExists = false
        this.errorMessage(`Error fetching client.`)
      }
      this.isInitializing = false
    },
    /**
     * Send an update API request for the data-type.
     * @param {string} type Client.TYPE
     */
    async updateClientData (type) {
      this.saving[type] = true

      const payload = {
        ...this.client.getData(type),
        ...this.apiCallMetadata,
      }

      // Send update call.
      let updatedData = null
      try {
        updatedData = await this.updateData({ type, payload })
      }
      catch (error) {
        this.enqueueMessage({ action: ACTIONS.UPDATE, type: MESSAGE_TYPE.ERROR, data: payload, dataType: type, error })
      }

      // Save the data. If it's an iterable type, fetch a new list first.
      if (isTypeIterable(type)) {
        try {
          updatedData = await this.fetchList({ type: type, client_id: payload.client_id, })
        }
        catch (error) {
          this.errorMessage(`Error fetching replacement data for the '${type}' data iterable: ${error}`)
        }
      }
      this.enqueueMessage({ action: ACTIONS.UPDATE, type: MESSAGE_TYPE.SUCCESS, data: payload, dataType: type })
      this.client.setData(updatedData, type)
      this.client.disableEdit(type)
      this.cacheClient({ client: this.client, client_id: payload.client_id })

      this.saving[type] = false
    },
  },
  async created() {
    this.isInitializing = true
    this.client_id = this.$route.params.id

    this.panelCount = 5
    this.panelsExpanded = range(this.panelCount)
    this.panel = []

    this.initTemplateConstants()
    await this.initClient()

    this.isInitializing = false
  }
}
</script>

<style scoped>
button {
  outline: none;
}
div.v-list {
  cursor: pointer;
}
</style>